import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const SiteLock = lazy(() => import('sections/hero/SiteLock.js'));
const SiteLockPrecios = lazy(() => import('sections/services/SiteLockPrecios.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const BannerSSLsitelock = lazy(() => import('sections/hero/BannerSSLsitelock.js'));
const BotonSiteLock = lazy(() => import('sections/hero/BotonSiteLock.js'));
const SSLsanBlancoizq = lazy(() => import('sections/about/SSLsanBlancoizq.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const EspecSiteLock = lazy(() => import('sections/services/EspecSiteLock.js'));
const FaqSiteLock = lazy(() => import('sections/services/FaqSiteLock.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>SiteLock: Protección total para tu sitio web - Antimalware</title>
          <meta name="description" content="Protege tu sitio web con SiteLock. Soluciones avanzadas de seguridad para prevenir ataques, malware y vulnerabilidades. Mantén tu sitio seguro y confiable." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <SiteLock />
            <SiteLockPrecios />
            <EspecSiteLock />
            <BannerSSLsitelock />
            <BotonSiteLock />
            <SSLsanBlancoizq />
            <SeguridadInformatica />
            <FaqSiteLock />
            <BotonSanGeo />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`